import 'core-js/stable';
import 'regenerator-runtime/runtime';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import slick from "slick-carousel/slick/slick";
import Inputmask from "../.../../../node_modules/inputmask/dist/jquery.inputmask.min";

 $(".inputmask").inputmask({"mask": "+7 (999) 999-9999"});


gsap.registerPlugin(ScrollTrigger);



/*

ScrollTrigger.matchMedia({
	"(min-width: 992px)" : function() {

		const tween = gsap.timeline({
			scrollTrigger: {
				trigger: ".main",
				toggleActions: "play reverse restart reset",
			},
			repeat: 0,
			repeatDelay: 1
		});

		tween.to(".main", {
			duration: 0.5,
			opacity: 1,
			//ease: "bounce"
		})

		tween.to(".header", {
			duration: 0.75,
			opacity: 1,
			translateY: 0,
		}, '-=0.25') // Когда прошла 0.25 секунды анимации .main начинаем анимацию .header


		tween.to(".left-panel", {
			duration: 0.5,
			opacity: 1,
			translateX: 0,
		}, '-=0.5')

		tween.to(".main__title", {
			duration: 1,
			opacity: 1,
			translateY: 0
		}, '-=0.25')

		tween.to(".main__title span", {
			duration: 1,
			color: "#CFA604"
		}, '-=0.45')

		tween.to(".main__btns", {
			duration: 1,
			opacity: 1,
			translateY: 0
		}, '-=1.2')

		tween.to(".main__advantage-item", {
			duration: 1,
			opacity: 1,
			translateY: 0,
			stagger: 0.2,
		}, '-=0.7')


		tween.to(".main__coube", {
			duration: 0.7,
			opacity: 1,
			scale: 1,
			stagger: 0.1,
		}, '-=0.7')


		tween.to(".left-panel__social", {
			duration: 1.5,
			opacity: 1,
			translateY: 0,
			ease: "bounce",
		})


		gsap.to(".service-box",{
			scrollTrigger: {
				trigger: ".services",
				toggleActions: "play reverse restart reset",
				scrub: 1,
				end: "bottom bottom",
			},
			duration: 1.5,
			scale: 1,
			opacity: 1,
			stagger: 0.2
		});

		gsap.to(".reviews .container",{
			scrollTrigger: {
				trigger: ".reviews",
				toggleActions: "play reverse restart reset",
				scrub: 1,
				end: "bottom bottom",
			},
			duration: 1,
			y: 0,
			opacity: 1
		});


		gsap.to(".portfolio .container",{
			scrollTrigger: {
				trigger: ".portfolio",
				toggleActions: "play reverse restart reset",
				scrub: 1,
				end: "bottom bottom",
			},
			duration: 1,
			y: 0,
			opacity: 1
		});




		const company = gsap.timeline({
			scrollTrigger: {
				trigger: ".company",
				toggleActions: "play reverse restart reset",
				scrub: 2,
				start: "top center",
				end: "bottom bottom",
			},
			repeat: 0,
			repeatDelay: 1
		});

		company.to(".company__image", {
			duration: 0.5,
			opacity: 1,
			translateX: 0
		})

		company.to(".company__container", {
			duration: 0.5,
			opacity: 1,
			translateY: 0
		}, "-=0.5")


		ScrollTrigger.batch(".calculator__item", {
			onEnter: batch => gsap.to(batch, {
				opacity: 1,
				scale: 1,
				y: 0,
				stagger: 0.1,
			}),
		});

		ScrollTrigger.batch(".article", {
			onEnter: batch => gsap.to(batch, {
				opacity: 1,
				scale: 1,
				y: 0,
				stagger: 0.1,
			}),
			onLeave: batch => gsap.to(batch, {
				opacity: 0,
				scale: 1.5,
				stagger: 0.1
			}),
			onEnterBack: batch => gsap.to(batch, {
				opacity: 1,
				scale: 1,
				stagger: 0.1
			}),
			onLeaveBack: batch => gsap.to(batch, {
				opacity: 0,
				scale: 1.5,
				stagger: 0.1
			}),
		});

	}
})
*/

svg4everybody();

window.$ = $;
window.jQuery = $;
window.gsap = gsap;



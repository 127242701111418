import './vendor';
require('/src/js/vendor/magnific/jquery.magnific-popup.min.js')
// require('/src/js/vendor/fullcalendar/fc/lib/moment.min.js')
require('/src/js/vendor/fullcalendar/fullcalendar.js')
// require('/src/js/vendor/fullcalendar/fc/lang-all.js')
require('/src/js/vendor/customCalendar.js')

$(".accordeon dd").hide().prev().click(function() {
	$(this).parents(".accordeon").find("dd").not(this).slideUp().prev().removeClass("active");
	$(this).next().not(":visible").slideDown().prev().addClass("active");
});


$(".btn-mobile-menu").click(function() {
	$(this).toggleClass("is-active");
	$(".header__nav").slideToggle();
});

$(".nav__btn ").click(function() {
	$(this).toggleClass("is-active");
	$(this).parents("li").find("ul").slideToggle();
});


$('.expedition-slider').slick({
	dots: false,
	speed: 1000,
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 4,
	prevArrow: $("#expedition-prev"),
	nextArrow: $("#expedition-next"),
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true
		    }
		}
	]
});

$('.publications-slider').slick({
	dots: false,
	speed: 1000,
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 4,
	arrows: false,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true
		    }
		}
	]
});

$('.multimedia-slider ').slick({
	dots: false,
	speed: 1000,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 3,
	arrows: false,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true
		    }
		}
	]
});











/*Magnific модальное окно */
$('.popup-with-form').magnificPopup({
	type: 'inline',
	preloader: false,
	focus: '#name',
	mainClass: 'mfp-fade',
	callbacks: {
		beforeOpen: function() {
			if($(window).width() < 700) {
				this.st.focus = false;
			} else {
				this.st.focus = '#name';
			}
		}
	}
});




$(".tab-wrapper-1 .tab_item").not(":first").hide();


$(".tab-wrapper-1 .tab").click(function() {
	$(".tab-wrapper-1 .tab").removeClass("active").eq($(this).index()).addClass("active");
	$(".tab-wrapper-1 .tab_item").hide().eq($(this).index()).fadeIn()
	$(".publications-slider ").slick('reinit');
}).eq(0).addClass("active");



$(".tab-wrapper-2 .tab_item").not(":first").hide();

$(".tab-wrapper-2 .tab").click(function() {
	$(".tab-wrapper-2 .tab").removeClass("active").eq($(this).index()).addClass("active");
	$(".tab-wrapper-2 .tab_item").hide().eq($(this).index()).fadeIn()
	$(".multimedia-slider").slick('reinit');
}).eq(0).addClass("active");




$('.product-slider').slick({
	dots: true,
	speed: 1000,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	appendDots: $('.slick-dots-custom'),
	prevArrow: '<button type="button" class="btn-slider prev"><img src="/assets/build/images/sprites/svg/prev.svg"></button>',
	nextArrow: '<button type="button" class="btn-slider next"><img src="/assets/build/images/sprites/svg/next.svg"></button>',
	asNavFor: '.product-slider-thumb',
});

$('.product-slider-thumb').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	vertical: true,
	asNavFor: '.product-slider',
	focusOnSelect: true,
	arrows: false,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				vertical: false,
		    }
		}
	]
});


$(".scroll").on("click","a", function (event) {
    // исключаем стандартную реакцию браузера
    event.preventDefault();

    // получем идентификатор блока из атрибута href
    var id  = $(this).attr('data-scroll-to'),

    // находим высоту, на которой расположен блок
        top = $(id).offset().top;

    // анимируем переход к блоку, время: 800 мс
    $('body,html').animate({scrollTop: top}, 800);
});


/*Magnific галерея*/
$('.popup-gallery').magnificPopup({
	delegate: 'a',
	type: 'image',
	tLoading: 'Загрузка изображения #%curr%...',
	mainClass: 'mfp-fade mfp-img-mobile',
	gallery: {
		enabled: true,
		navigateByImgClick: true,
		preload: [0,1] // Will preload 0 - before current, and 1 after the current image
	},
	image: {
		tError: '<a href="%url%">Изображение #%curr%</a> не загружено.',
		titleSrc: function(item) {
			return  item.el.attr('title');
		}
	}
});
